<template>
    <div v-if="showPage" class="publishGuide-mian">
        <img src="./img/guidepic.png" alt="" style="width:180px;margin-top: 35px;">

        <div class="footer-fixed">
            <mmcButton class="footer_box" @click="navToPublish">
                立即发起项目
            </mmcButton>
        </div>
    </div>
</template>

<script>
import mmcButton from '@/components/mmcUI/mmc-btn-big';
import { mmcAjax, authChecker ,shareCtrl} from '@/common/js/mmcModule';

export default {
    data(){
        return {
            showPage:true
        }
    },
    methods: {
        navToPublish() {
            this.$router.push('/fund/publish/create');
        },
    },
    components: {
        mmcButton,
    },
    created() {
        let that = this;
        authChecker.check({ notneedphone: 1 }, () => {
            that.showPage = true
            console.log(mmcAjax);
            // let url = `${window.APIBasePath}mmc/withdrawals/bank/list`;
            // console.log(url);
            // mmcAjax({
            //     method: 'GET',
            //     url,
            //     crossDomain: true,
            // }).then((res) => {
            //     // console.log(111);
            //     // alertMsg(res.data);
            //     if (res.code == 0) {
            //         return res.data;
            //     }
            // });
            shareCtrl.init()

        });
//  shareCtrl.init()

    },
};
</script>

<style lang="less"  scoped>
.publishGuide-mian {
    margin: 20px;
    text-align: center;
    padding: 0 0 50px 0;
    h1 {
        font-size: 34px;
        color: #333333;
        margin: 0px;
    }
    h5 {
        font-size: 17px;
        color: #333333;
        margin: 10px 0 0;
    }
    .text-blue {
        color: #009bff !important;
    }

    div {
        img {
            width: 64px;
        }
        .icon {
            width: 64px;
            height: 64px;
            display: block;
            margin: auto;
        }
        .first {
            background: url('./img/first.png') no-repeat;
            background-size: contain;
        }
        .second {
            background: url('./img/second.png') no-repeat;
            background-size: contain;
        }
        .third {
            background: url('./img/third.png') no-repeat;
            background-size: contain;
        }
    }
    .publishGuide-mian-arrow {
        margin: 40px 0 65px;
        position: relative;
    }
    .publishGuide-mian-arrow:after {
        content: '';
        width: 15px;
        height: 25px;
        background: url('./img/arrowIcon.png') no-repeat;
        position: absolute;
        background-size: contain;
        margin: 10px -10px;
    }
    .footer-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
    }

    .footer_box {
        // background: #ccc;
    }
}
</style>
